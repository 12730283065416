import { useState, useEffect } from "react";
import { Row, Col, Container, Button, Form, Modal } from "react-bootstrap";
import payimage from "../../image/payimage.png";
import payimg1 from "../../image/payimg1.png";
import "./footer.css";
import constant from "../js/constant";
import { ApiGetMethod, ApiPostMethod } from "../js/helper";
import Swal from "sweetalert2";
import {
  NavLink,
  useNavigate,
  useLocation,
  useHistory,
} from "react-router-dom";
import { validate } from "../validator/validate";
import LoginModal from "../login/loginmodal";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaFacebookF, FaYoutube, FaInstagram } from "react-icons/fa";

const Footer = () => {
  const navigate = useNavigate();
  const formData = new FormData();
  const [show, setShow] = useState(false);

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  // const handleSectionClick = (sectionId) => {
  //   const section = document.getElementById(sectionId);
  //   section.scrollIntoView({ behavior: "smooth" });
  // };

  const handleClose = () => {
    setShow(false);
    setError({
      brand: "",
      model: "",
      variant: "",
      price: "",
      color: "",
      fueltype: "",
      transmission: "",
      other: "",
    });
    setEnquiryBrand("");
    setEnquiryModel("");
    setEnquiryVarient("");
    setEnquiryPrice("");
    setEnquiryColor("");
    setEnquiryFueltype("");
    setEnquiryTransmission("");
    setEnquiryOther("");
  };
  const [showLoginModal, setShowLoginModal] = useState(false);

  const handleModalClose = () => {
    setShowLoginModal(false);
  };

  const handleModalShow = () => {
    setShowLoginModal(true);
  };
  const handleShow = () => {
    setShow(true);
  };

  const [enquiryBrand, setEnquiryBrand] = useState();
  const [enquiryModel, setEnquiryModel] = useState();
  const [enquiryVarient, setEnquiryVarient] = useState();
  const [enquiryPrice, setEnquiryPrice] = useState();
  const [enquiryColor, setEnquiryColor] = useState();
  const [enquiryFueltype, setEnquiryFueltype] = useState();
  const [enquiryTransmission, setEnquiryTransmission] = useState();
  const [enquiryOther, setEnquiryOther] = useState();
  const [enquiryResponse, setEnquiryResponse] = useState();
  const [error, setError] = useState({
    brand: "",
    model: "",
    variant: "",
    price: "",
    color: "",
    fueltype: "",
    transmission: "",
    other: "",
  });
  const [websiteLinks, setWebsiteLinks] = useState({});
  const [phoneNumber, setPhoneNumber] = useState();

  function handleError(e) {
    const { name, value } = e.target;

    setError((prevData) => ({
      ...prevData,
      [name]: validate(name, value),
    }));
  }

  const handleBrand = (event) => {
    setEnquiryBrand(event.target.value);
  };

  const handleModel = (event) => {
    setEnquiryModel(event.target.value);
  };

  const handleVarient = (event) => {
    setEnquiryVarient(event.target.value);
  };

  const handlePrice = (event) => {
    setEnquiryPrice(event.target.value);
  };

  const handleColor = (event) => {
    setEnquiryColor(event.target.value);
  };

  const handleFueltype = (event) => {
    setEnquiryFueltype(event.target.value);
  };

  const handleTransmission = (event) => {
    setEnquiryTransmission(event.target.value);
  };

  const handleOther = (event) => {
    setEnquiryOther(event.target.value);
  };

  const userId_Enquiry = localStorage.hasOwnProperty("user")
    ? JSON.parse(localStorage.getItem("user"))
    : "";
  const errorFind = () => {
    const objectarray = Object.keys(error);
    // console.log("=[=[=[=[", error);
    var output = true;
    for (let index = 0; index < objectarray.length; index++) {
      const element = objectarray[index];
      if (error[element] != "") {
        output = false;
        break;
      }
    }
    return output;
  };

  const handleEnquirySubmit = () => {
    if (localStorage.hasOwnProperty("user")) {
      const errorcheck = errorFind();
      // console.log("=-=-=-=-=-", errorcheck);
      if (errorcheck) {
        formData.append("brand", enquiryBrand);
        formData.append("price", enquiryPrice);
        formData.append("userId", userId_Enquiry._id);
        formData.append("fuelType", enquiryFueltype);
        formData.append("transmission", enquiryTransmission);
        formData.append("color", enquiryColor);
        formData.append("model", enquiryModel);
        formData.append("varient", enquiryVarient);
        formData.append("other", enquiryOther);
        formData.append("status", 0);

        let data = formData;
        let url = `${constant.baseurl}addEnquiry`;
        ApiPostMethod(url, data).then((response) => {
          if (response.error == false) {
            setEnquiryResponse(response);
            Swal.fire({
              title: "Success!",
              text: response.message,
              icon: "success",
              confirmButtonText: "OK",
            });
            setEnquiryBrand("");
            setEnquiryModel("");
            setEnquiryVarient("");
            setEnquiryPrice("");
            setEnquiryColor("");
            setEnquiryFueltype("");
            setEnquiryTransmission("");
            setEnquiryOther("");
          } else {
            Swal.fire({
              title: "Error!",
              text: response.message,
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: "please fill the fields",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } else {
      handleModalShow();
      handleClose();
    }
  };

  useEffect(() => {
    let data = formData;
    let url = `${constant.baseurl}settings`;
    ApiGetMethod(url, data).then((response) => {
      if (response.error == false) {
        setWebsiteLinks(response.data);
        const Number = response.data.number;
        // const phoneNumber = Number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
        setPhoneNumber(Number);
      } else {
      }
    });
  }, []);

  return (
    <>
<div className="enquiry sticky-btn">
        <Button className="buttn-theme" onClick={handleShow}>
          <svg
            width="25"
            height="25"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5 11.25C10.5 10.8358 10.8358 10.5 11.25 10.5H18.75C19.1641 10.5 19.5 10.8358 19.5 11.25C19.5 11.6642 19.1641 12 18.75 12H11.25C10.8358 12 10.5 11.6642 10.5 11.25ZM11.25 15C10.8358 15 10.5 15.3358 10.5 15.75C10.5 16.1642 10.8358 16.5 11.25 16.5H18.75C19.1641 16.5 19.5 16.1642 19.5 15.75C19.5 15.3358 19.1641 15 18.75 15H11.25ZM10.5 20.25C10.5 19.8358 10.8358 19.5 11.25 19.5H14.25C14.6642 19.5 15 19.8358 15 20.25C15 20.6642 14.6642 21 14.25 21H11.25C10.8358 21 10.5 20.6642 10.5 20.25ZM9 3.75C9 3.33579 9.33579 3 9.75 3C10.1642 3 10.5 3.33579 10.5 3.75V4.5H14.25V3.75C14.25 3.33579 14.5858 3 15 3C15.4141 3 15.75 3.33579 15.75 3.75V4.5H19.5V3.75C19.5 3.33579 19.8358 3 20.25 3C20.6641 3 21 3.33579 21 3.75V4.5H21.75C22.9926 4.5 24 5.50735 24 6.75V13.8474C23.5107 14.0571 23.0524 14.3616 22.653 14.761L22.5 14.914V6.75C22.5 6.33579 22.1641 6 21.75 6H8.25C7.83579 6 7.5 6.33579 7.5 6.75V24.75C7.5 25.1642 7.83579 25.5 8.25 25.5H13.8307L13.5857 26.4798C13.5419 26.6551 13.5152 26.8288 13.5043 27H8.25C7.00735 27 6 25.9926 6 24.75V6.75C6 5.50735 7.00735 4.5 8.25 4.5H9V3.75ZM23.7138 15.8217L16.4697 23.0658C16.0473 23.4882 15.7476 24.0174 15.6027 24.5968L15.0411 26.8437C14.7968 27.8208 15.6819 28.7058 16.659 28.4616L18.9058 27.8998C19.4853 27.7549 20.0145 27.4554 20.4369 27.033L27.681 19.7889C28.7764 18.6933 28.7764 16.9172 27.681 15.8217C26.5855 14.7261 24.8092 14.7261 23.7138 15.8217Z"
              fill="#FFFEFE"
            />
          </svg>
          <span>Enquire Now</span>
        </Button>
        <LoginModal show={showLoginModal} handleClose={handleModalClose} />
        <Modal className="enquiry-modal" show={show} onHide={handleClose}>
          <Modal.Header closeButton></Modal.Header>
          <Form>
            <Modal.Body>
              <Col lg="12" className="d-flex flex-wrap">
                <Col lg="6" className="enquiry-input form-group">
                  <Form.Group controlId="formBrand">
                    <Form.Label>Brand<span className="required-field">*</span></Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter brand"
                      name="brand"
                      onChange={handleBrand}
                      value={enquiryBrand}
                      onBlur={handleError}
                    />
                    {error.brand && (
                      <p className="text-danger mb-1">{error.brand}</p>
                    )}
                  </Form.Group>
                </Col>
                <Col lg="6" className="enquiry-input form-group">
                  <Form.Group controlId="formModel">
                    <Form.Label>Model<span className="required-field">*</span></Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter model"
                      name="model"
                      onChange={handleModel}
                      onBlur={handleError}
                      value={enquiryModel}
                    />
                    {error.model && (
                      <p className="text-danger mb-1">{error.model}</p>
                    )}
                  </Form.Group>
                </Col>
                <Col lg="6" className="enquiry-input form-group">
                  <Form.Group controlId="formvariant">
                    <Form.Label>varient<span className="required-field">*</span></Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter variant"
                      name="variant"
                      onChange={handleVarient}
                      onBlur={handleError}
                      value={enquiryVarient}
                    />
                    {error.variant && (
                      <p className="text-danger mb-1">{error.variant}</p>
                    )}
                  </Form.Group>
                </Col>
                <Col lg="6" className="enquiry-input form-group">
                  <Form.Group controlId="formPrice">
                    <Form.Label>Price<span className="required-field">*</span></Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter price"
                      name="price"
                      onChange={handlePrice}
                      onBlur={handleError}
                      value={enquiryPrice}
                    />
                    {error.price && (
                      <p className="text-danger mb-1">{error.price}</p>
                    )}
                  </Form.Group>
                </Col>
                <Col lg="6" className="enquiry-input form-group">
                  <Form.Group controlId="formColor">
                    <Form.Label>Color<span className="required-field">*</span></Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter color"
                      name="color"
                      onChange={handleColor}
                      onBlur={handleError}
                      value={enquiryColor}
                    />
                    {error.color && (
                      <p className="text-danger mb-1">{error.color}</p>
                    )}
                  </Form.Group>
                </Col>
                <Col lg="6" className="enquiry-input form-group">
                  <Form.Group controlId="formFuelType">
                    <Form.Label>Fuel Type<span className="required-field">*</span></Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter fuel type"
                      name="fueltype"
                      onChange={handleFueltype}
                      onBlur={handleError}
                      value={enquiryFueltype}
                    />
                    {error.fueltype && (
                      <p className="text-danger mb-1">{error.fueltype}</p>
                    )}
                  </Form.Group>
                </Col>
                <Col lg="6" className="enquiry-input form-group">
                  <Form.Group controlId="formTransmission">
                    <Form.Label>Transmission<span className="required-field">*</span></Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter transmission"
                      name="transmission"
                      onChange={handleTransmission}
                      onBlur={handleError}
                      value={enquiryTransmission}
                    />
                    {error.transmission && (
                      <p className="text-danger mb-1">{error.transmission}</p>
                    )}
                  </Form.Group>
                </Col>
                <Col lg="6" className="enquiry-input form-group">
                  <Form.Group controlId="formOther">
                    <Form.Label>Other</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter other"
                      name="other"
                      onChange={handleOther}
                      value={enquiryOther}
                    />
                  </Form.Group>
                </Col>
              </Col>
            </Modal.Body>
            <Modal.Footer>
              <Col lg="12" className="text-center">
                <Button className="buttn-submit" onClick={handleEnquirySubmit}>
                  Submit
                </Button>
              </Col>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
      <footer>
        <Container fluid>
          <Col lg="12" className="d-flex justify-content-between">
            <Col lg="2">
              <div className="footer-logo">
                <img src={websiteLinks.logo} alt="" className="w-100" />
              </div>
              <div className="social-media">
                <div className="media-heading">Follow Us</div>

                <div className="social position-relative">
                <ul>
                  <li>
                    <a href={websiteLinks.facebookLink}><FaFacebookF /></a>
                  </li>
                  <li>
                    <a href={websiteLinks.youTubeLink}><FaYoutube /></a>
                  </li>
                  <li>
                    <a href={websiteLinks.instagramLink}><FaInstagram /></a>
                  </li>
                </ul>
                </div>
              </div>
            </Col>
            <Col lg="5" className="quick-links">
              <div className="quick-heading">
                <h5 className="text-color">USEFUL LINKS</h5>
              </div>
              <Col lg="12" className="d-flex flex-wrap">
                <Col lg="6">
                  <Col lg="12" className=" pb-1">
                    <NavLink
                      to="/About"
                      className="hvr-underline-from-left"
                      onClick={handleClick}
                    >
                      About Us
                    </NavLink>
                  </Col>
                  <Col lg="12" className=" pb-1">
                    <NavLink to="/Blog" className="hvr-underline-from-left">
                      Blogs
                    </NavLink>
                  </Col>
                  <Col lg="12" className=" pb-1">
                    <NavLink
                      to="/?section=freq-asked-que"
                      className="hvr-underline-from-left"
                      // onClick={() => handleSectionClick("freq-asked-que")}
                    >
                      FAQs
                    </NavLink>
                  </Col>
                  <Col lg="12" className=" pb-1">
                    <NavLink
                      to="/?section=Help"
                      className="hvr-underline-from-left"
                      // onClick={() => handleSectionClick("Help")}
                    >
                      Help
                    </NavLink>
                  </Col>
                </Col>
                <Col lg="6">
                  <Col lg="12" className=" pb-1">
                    <NavLink
                      to="/TermsAndCondition"
                      className="hvr-underline-from-left"
                      onClick={handleClick}
                    >
                      Terms & Condition
                    </NavLink>
                  </Col>
                  <Col lg="12" className=" pb-1">
                    <NavLink
                      to="/PrivacyPolicy"
                      className="hvr-underline-from-left"
                      onClick={handleClick}
                    >
                      Privacy Policy
                    </NavLink>
                  </Col>
                  <Col lg="12" className=" pb-1">
                    <NavLink
                      to="/ContactUs"
                      className="hvr-underline-from-left"
                      onClick={handleClick}
                    >
                      Contact Us
                    </NavLink>
                  </Col>
                </Col>
              </Col>
            </Col>
            <Col lg="3">
              <div className="contact-button">
                <Button className="callButtn" onClick={() => window.location.href = `tel:${websiteLinks.number}`}>
                  <span className="pe-2 me-2">
                    <svg
                      width="42"
                      height="43"
                      viewBox="0 0 42 43"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_422_1855)">
                        <path
                          d="M26.474 9.39616C28.1629 9.72568 29.7151 10.5517 30.9319 11.7685C32.1486 12.9852 32.9747 14.5374 33.3042 16.2264M26.474 2.47949C29.9829 2.86931 33.2551 4.44067 35.7531 6.93557C38.2511 9.43047 39.8266 12.7006 40.2208 16.2091M38.4917 30.0078V35.1953C38.4936 35.6769 38.395 36.1536 38.2021 36.5948C38.0091 37.0361 37.7262 37.4322 37.3713 37.7577C37.0164 38.0833 36.5975 38.3311 36.1413 38.4854C35.6851 38.6397 35.2017 38.697 34.7221 38.6537C29.4012 38.0755 24.29 36.2573 19.7994 33.3451C15.6214 30.6903 12.0792 27.1481 9.42438 22.9701C6.50205 18.4591 4.68343 13.3231 4.11584 7.97824C4.07263 7.50007 4.12946 7.01814 4.28271 6.56313C4.43596 6.10813 4.68227 5.69002 5.00596 5.33542C5.32965 4.98082 5.72363 4.69751 6.16281 4.50352C6.602 4.30953 7.07676 4.20911 7.55688 4.20866H12.7444C13.5836 4.2004 14.3971 4.49757 15.0334 5.04477C15.6697 5.59197 16.0853 6.35187 16.2027 7.18283C16.4217 8.84294 16.8277 10.473 17.4131 12.0418C17.6458 12.6607 17.6961 13.3333 17.5582 13.98C17.4203 14.6266 17.0999 15.2202 16.635 15.6903L14.439 17.8864C16.9005 22.2154 20.4849 25.7998 24.814 28.2614L27.01 26.0653C27.4801 25.6004 28.0737 25.28 28.7204 25.1421C29.367 25.0042 30.0396 25.0546 30.6585 25.2872C32.2274 25.8726 33.8574 26.2787 35.5175 26.4976C36.3575 26.6161 37.1246 27.0392 37.673 27.6864C38.2213 28.3336 38.5127 29.1598 38.4917 30.0078Z"
                          stroke="#FFFEFE"
                          stroke-width="2.76667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_422_1855">
                          <rect
                            width="41.5"
                            height="41.5"
                            fill="white"
                            transform="translate(0.449951 0.75)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                 {websiteLinks.number}
                </Button>
                <Col lg="12">
                  <img src={payimage} alt="" className="w-100 pt-3" />
                  <img src={payimg1} alt="" className="w-100 pt-3" />
                </Col>
              </div>
            </Col>
          </Col>
        </Container>
      </footer>
      
    </>
  );
};
export default Footer;
