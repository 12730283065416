import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Image } from "react-bootstrap";
import { Modal, Button, Form } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
// import { Chart, LinearScale, CategoryScale, BarController, BarElement, Tooltip, Title } from 'chart.js';
import "chartjs-plugin-datalabels";
import { ApiPostMethod, ApiGetMethod } from "../js/helper";
import constant from "../js/constant";
import Swal from "sweetalert2";
import { saveAs } from "file-saver";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import WishlistButton from "../sharemodal/whishlistButton";
import ViewAllPlans from "../userProfile/viewPlansModal";
import "react-toastify/dist/ReactToastify.css";
import badge from "../index/inspectbadge.png"
import "./single.css";
import priceguidOwner from "../../image/replacePriceguid.png"
import { Bar } from "react-chartjs-2";
import {FaShareAlt} from "react-icons/fa"
import ShareModal from "../inviteModal/shareModal";
import LoginModal from "../login/loginmodal";

const SingleCarDetail = () => {

  const navigate = useNavigate();
  const [showAllPlans, setShowAllPlans] = useState(false);

  const handleAllPLansView = () => {
    setShowAllPlans(true);
  };
  const handleAllPLansClose = () => {
    setShowAllPlans(false);

  };
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleHideModal = () => {
    setShowModal(false);
  };

  const [showLoginModal, setShowLoginModal] = useState(false);

  const handleModalClose = () => {
    setShowLoginModal(false);
  };

  const handleModalShow = () => {
    setShowLoginModal(true);
  };

  const [apiResponse, setApiResponse] = useState([]);
  const [leads, setLeads] = useState([]);
  const [carInformation, setCarInformation] = useState({});
  const [fairPrice, setFairPrice] = useState();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [images, setImages] = useState([]);
  const [specification, setSpecification] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [carBrandName, setCarBrandName] = useState([]);
  const [carDataDetail, setCarDataDetail] = useState([]);
  const [postedCarDetails, setpostedCarDetails] = useState([]);
  const [minofferamount, setMinOfferAmount] = useState("");
  const [depricationdetails, setDepricationdetails] = useState();
  const [similarcars, setSimilarCars] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [carsleads, setCarsLeads] = useState([]);

  const [carpostIdUrl, setcarpostIdUrl] = useState("");
  const location = useLocation();
  const query_Params = new URLSearchParams(location.search);
  const carPost = query_Params.get("carPostId");
  useEffect(() => {
    const query_Params = new URLSearchParams(location.search);
    const carPost = query_Params.get("carPostId");
    setcarpostIdUrl(carPost || "");
  }, [location.search]);
  const userInfo = localStorage.hasOwnProperty("user")
    ? JSON.parse(localStorage.getItem("user"))
    : "";
  const userId = userInfo._id ? userInfo._id : "";
  const formData = new FormData();
  useEffect(() => {
    setLoading(true);
    formData.append("carPostId", carPost);
    formData.append("userId", userId);
    let data = formData;
    let url = `${constant.baseurl}viewPostedCarDetails`;
    ApiPostMethod(url, data).then((response) => {
      if (response.error == false) {
        setErrorMessage(false);
        setApiResponse(response);
        setFairPrice(response.fairPrice);
        setCarInformation(response.postedCarDetails);
        setCarBrandName(response.brand[0]);
        setCarDataDetail(response.carDataDetails[0]);
        setpostedCarDetails(response.postedCarDetails);
        setDepricationdetails(response.depeciationDetails);
        setMinOfferAmount(response.minOfferAmount);
        setSpecification(carDataDetail.specification);
        setSimilarCars(response.similarCars);
        setCarsLeads(response.leadDetails);
        const carImageArray = response.postedCarDetails.carImages;
        setImages(carImageArray);
        setLoading(false);

            const object = response.carDataDetails[0].price[0]
            // console.log("cardetails",object);
            if (object.hasOwnProperty(response.postedCarDetails.year)) {
               window.carDetailsForYear = object[response.postedCarDetails.year];
              // console.log(window.carDetailsForYear);
            } else {
              // console.log(`Car details for year ${response.postedCarDetails.year} not found`);
            }

      } else {
        setErrorMessage(true);
        setLoading(false);
      }
    });
  }, [carPost]);


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex);
    },
  };

  const QuickSell = {
    dots: true,
    arrows: false,
    infinite: false,
    loop: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    lazyLoad: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          loop:true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
          loop:true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          loop:true,
        },
      },
    ],
  };

  const [offerPrice, setOfferPrice] = useState();
  const [makeOffer, setMakeOffer] = useState(
    apiResponse.userOfferedPrice > 0 ? true : false
  );
  const handleOfferPrice = (event) => {
    setOfferPrice(event.target.value);
  };

  

  const handleMakeOffer = () => {
    
    formData.append("carPostId", carInformation._id);
    formData.append("offerPrice", offerPrice);
    formData.append("userId", userInfo._id);
    let data = formData;
    let url = `${constant.baseurl}makeOffer`;
    ApiPostMethod(url, data).then((response) => {
      if (response.error == false) {
        Swal.fire({
          title: "Success",
          text: response.messge,
          icon: "success",
          confirmButtonText: "OK",
        });
        setMakeOffer(true);
        const button = document.querySelector(".offer-submit");
        if (button) {
          button.disabled = true;
          button.style.cursor = "not-allowed";
        }
      } else {
        Swal.fire({
          title: "Error",
          text: response.messge,
          icon: "error",
          confirmButtonText: "OK",
        });
        setOfferPrice("");
        setMakeOffer(false);
      }
    });
  };
  const [report, setReport] = useState();
  const downloadReport = (name, model, varient) => {
    setIsLoading(true);
    formData.append("carPostId", carPost);
    let data = formData;
    let url = `${constant.baseurl}inspectionReport`;

    fetch(url, {
      method: "POST",
      body: data,
      responseType: "blob",
    })
      .then((res) => res.blob())
      .then((blob) => {
        saveAs(blob, name + model + varient + ".pdf");
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const showToast = () => {
    toast.info("Please login to make offer!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        navigate("/login");
      },
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB").replace(/\//g, "-");
  };

  

  return (
    <>
      {loading ? (
        <div class="container-loader">
          <svg
            class="loader"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 80 80"
          >
            <g class="car">
              <path d="M73.1,53.47V46.4a4.9,4.9,0,0,0-4.9-4.89H56.45l-7.59-9.25a4.08,4.08,0,0,0-3.22-1.59H22.83a4.12,4.12,0,0,0-3.66,2.22v0L15,41.51H11.18a3.94,3.94,0,0,0-3.94,3.94v7.8A2,2,0,0,0,6.15,55v1.81a2,2,0,0,0,2,2H71.87a2,2,0,0,0,2-2V55A2,2,0,0,0,73.1,53.47Zm-65,3.78a.42.42,0,0,1-.42-.42V55a.42.42,0,0,1,.42-.42H20.69v.71H17.06l-2,0c-.65,0-1.3,0-1.95.05a.55.55,0,0,0,0,1.09c.65,0,1.3,0,1.95.05l2,0H21c1.31,0,2.61,0,3.92-.07s2.61-.13,3.91-.28a.27.27,0,0,0,0-.53c-1.3-.15-2.61-.23-3.91-.28-.88,0-1.76,0-2.64-.05V54.6H55.44v.72q-1.26,0-2.52.06c-1.05,0-2.09.13-3.14.28a.27.27,0,0,0-.22.23.26.26,0,0,0,.22.3c1,.15,2.09.23,3.14.28s2.09.05,3.13.07h5.68c1,0,2.09,0,3.13-.07S67,56.34,68,56.19a.27.27,0,0,0,.22-.23.25.25,0,0,0-.22-.3c-1-.15-2.09-.23-3.14-.28s-2.09-.05-3.13-.07H57v-.7H71.87a.42.42,0,0,1,.42.42v1.81a.42.42,0,0,1-.42.42Zm3-14.19h9.51V53H8.8V51.28h2.77a.78.78,0,0,0,.78-.78v-5a.77.77,0,0,0-.78-.77H8.91A2.38,2.38,0,0,1,11.18,43.06ZM22.83,32.23H45.64a2.53,2.53,0,0,1,2,1l0,0,6.79,8.27h-1.8l-6.3-7.64a1.39,1.39,0,0,0-1.1-.54H22.83a1.49,1.49,0,0,0-1.3.79l0,.06-3.14,7.33H16.74l3.82-7.91A2.56,2.56,0,0,1,22.83,32.23Zm15.44,9.28V34.89h6.89l5.47,6.62Zm-1.56,0H25.05V34.89H36.71Zm-13.21,0H20.06l2.83-6.62h.61Zm-1.25,1.55H36.71V53H22.25Zm16,0h15l.93,1.68L55.44,47V53H38.27Zm32.94,3h-2a.47.47,0,0,1-.47-.46V43.43A3,3,0,0,1,71.21,46Zm-2.6,1.87h2.93V53H57V46.78a.79.79,0,0,0-.1-.37l-1.84-3.35H66.9V46.2A1.71,1.71,0,0,0,68.61,47.91ZM10.79,49.72h-2v-.59h1a.39.39,0,0,0,.39-.39V47.26a.38.38,0,0,0-.39-.38h-1v-.6h2Z" />
              <path d="M42.67,44.37H40.25a.78.78,0,0,0,0,1.56h2.42a.78.78,0,0,0,0-1.56Z" />
              <path d="M28.83,37.49c.33.39.68.77,1,1.13a13.8,13.8,0,0,0,1.13,1,8.81,8.81,0,0,0,1.27.9.15.15,0,0,0,.13,0,.14.14,0,0,0,.06-.18,10.84,10.84,0,0,0-.9-1.28c-.33-.39-.67-.77-1-1.13s-.74-.7-1.14-1a8.81,8.81,0,0,0-1.27-.9A.15.15,0,0,0,28,36a.13.13,0,0,0,0,.19A9.61,9.61,0,0,0,28.83,37.49Z" />
              <path d="M27.85,39.62a6.17,6.17,0,0,0,.48.54,4.2,4.2,0,0,0,.54.48,3,3,0,0,0,.63.38h.07a.09.09,0,0,0,.05-.12,3.38,3.38,0,0,0-.38-.64c-.15-.19-.31-.37-.47-.54a4.13,4.13,0,0,0-.55-.47,2.64,2.64,0,0,0-.63-.38s-.05,0-.07,0a.09.09,0,0,0-.05.12A3.3,3.3,0,0,0,27.85,39.62Z" />
              <path d="M31.15,36.32a6.17,6.17,0,0,0,.48.54,4.78,4.78,0,0,0,.54.47,3.33,3.33,0,0,0,.63.39h.08a.11.11,0,0,0,0-.13,2.85,2.85,0,0,0-.38-.63,6.06,6.06,0,0,0-.47-.54,4.78,4.78,0,0,0-.54-.47,3.42,3.42,0,0,0-.64-.39.09.09,0,0,0-.07,0,.1.1,0,0,0-.05.13A3.64,3.64,0,0,0,31.15,36.32Z" />
              <path d="M43.86,39.2a13.82,13.82,0,0,0-1-1.14c-.37-.36-.75-.7-1.15-1a8,8,0,0,0-1.3-.87.19.19,0,0,0-.15,0,.17.17,0,0,0-.06.22,8.13,8.13,0,0,0,.86,1.3,12.32,12.32,0,0,0,1,1.14,13.94,13.94,0,0,0,1.14,1,7.92,7.92,0,0,0,1.3.86.16.16,0,0,0,.15,0,.16.16,0,0,0,.07-.21A8.51,8.51,0,0,0,43.86,39.2Z" />
              <path d="M44,36.14a3.25,3.25,0,0,0-.82-.45h-.1a.16.16,0,0,0-.09.18,4,4,0,0,0,.45.82A5.22,5.22,0,0,0,44.71,38a4,4,0,0,0,.82.45.17.17,0,0,0,.1,0,.15.15,0,0,0,.08-.19,3.57,3.57,0,0,0-.44-.82,5.43,5.43,0,0,0-.59-.68A5.35,5.35,0,0,0,44,36.14Z" />
              <path d="M31.22,55.61H29.78a.32.32,0,0,0,0,.63h1.44a.32.32,0,0,0,0-.63Z" />
              <path d="M70.42,55.61H69a.32.32,0,0,0,0,.63h1.44a.32.32,0,0,0,0-.63Z" />
              <path d="M48.8,55.61H47.36a.32.32,0,0,0,0,.63H48.8a.32.32,0,0,0,0-.63Z" />
            </g>
            <g class="wheel back-wheel">
              <circle cx="18" cy="56" r="6.75" />
              <circle class="wheel-details" cx="18." cy="56" r="3.12" />
              <circle cx="18" cy="56" r="1.85" />
              <path
                class="wheel-details"
                d="M59.27,51.11a4.39,4.39,0,0,1,2,.26l.47.21.44.25.42.31.38.34a5,5,0,0,1,1.11,1.71,4.56,4.56,0,0,1,.26,2,.14.14,0,0,1-.16.13.16.16,0,0,1-.13-.13h0a5.71,5.71,0,0,0-.48-1.79,5.28,5.28,0,0,0-1-1.48,5.14,5.14,0,0,0-1.48-1,6,6,0,0,0-1.78-.48h0a.14.14,0,0,1-.12-.16A.13.13,0,0,1,59.27,51.11Z"
              />
            </g>

            <g class="wheel front-wheel">
              <circle cx="59" cy="56" r="6.75" />
              <circle class="wheel-details" cx="59" cy="56" r="3.12" />
              <circle cx="59" cy="56" r="1.85" />
              <path
                class="wheel-details"
                d="M59.27,51.11a4.39,4.39,0,0,1,2,.26l.47.21.44.25.42.31.38.34a5,5,0,0,1,1.11,1.71,4.56,4.56,0,0,1,.26,2,.14.14,0,0,1-.16.13.16.16,0,0,1-.13-.13h0a5.71,5.71,0,0,0-.48-1.79,5.28,5.28,0,0,0-1-1.48,5.14,5.14,0,0,0-1.48-1,6,6,0,0,0-1.78-.48h0a.14.14,0,0,1-.12-.16A.13.13,0,0,1,59.27,51.11Z"
              />
            </g>

            <line class="line line-1" x1="77" y1="37" x2="4" y2="37" />
            <line class="line line-2" x1="77" y1="49" x2="4" y2="49" />
            <line
              class="line line-floor"
              x1="76.5"
              y1="62.43"
              x2="3.5"
              y2="62.43"
            />
            <path
              class="cloud"
              d="M71,26.15a3.37,3.37,0,0,0-3.29-2.65,2.11,2.11,0,0,0-.36,0,5,5,0,0,0-9.73,1.32,3.75,3.75,0,0,0-.82-.09,3.83,3.83,0,0,0-.08,7.65H70.87A3.13,3.13,0,0,0,71,26.15Z"
            />
          </svg>
        </div>
      ) : (
        <Container fluid className="single-details top_class">
          <Col lg="12" className="CarDetails-page">
            <Col lg="12" className="details-Section d-flex">
              <Col lg="7" className="image-specification-section">
                {/* <Col lg="12" className="image-section">
                  {images.length > 0 && (
                    <img src={images[currentSlide]} alt="Preview" />
                  )}
                  <Slider {...settings} className="singlecar_slider">
                    {images.map((image, index) => (
                      <img key={index} src={image} alt={`Image ${index}`} />
                    ))}
                  </Slider>
                </Col> */}
                <Col lg="12" className="image-section position-relative overflow-hidden">
                  {images.length > 0 && (
                    <>
                      {images[currentSlide].endsWith('.mp4') ? (
                        <video controls>
                          <source src={images[currentSlide]} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <>
                        <img src={images[currentSlide]} alt="Preview" />
                        <div>
                        {postedCarDetails.city === 'pune' && (
                          <div className="inspected_badge_single">
                            <div>
                              <Image className="badge_img" src={badge} style={{ height: "22px" }} />
                            </div>
                          </div>
                        )}
                      </div>
                      </>
                    )}
                    </>
                  )}
                  <Slider {...settings} className="singlecar_slider">
                    {images.map((image, index) => (
                      <React.Fragment key={index}>
                        {image.endsWith('.mp4') ? (
                          <video controls>
                            <source src={image} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img src={image} alt={`Image ${index}`} className="sigle_car_slider_img" />
                        )}
                      </React.Fragment>
                    ))}
                  </Slider>
                </Col>
                <Col lg="12" className="specifiaction-section">
                  <Tabs
                    defaultActiveKey="overview"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                  >
                    <Tab
                      eventKey="overview"
                      title="Overview"
                      className="flex overview"
                    >
                      <Col lg="12" className="d-flex">
                        <Col
                          lg="12"
                          className="d-flex flex-wrap justify-content-around overv-wrap"
                        >
                          <Col
                            lg="5"
                            className="d-flex justify-content-between overv-inside"
                          >
                            <h6>Car Number</h6>
                            <h6 className="font-weight-bold text-uppercase">
                            {carInformation.carNumber
                              ? carInformation.carNumber
                                  .replace(/\s/g, "")
                                  .substring(0, 4)+ "-XX-XXXX"
                              : ""}
                            </h6>
                          </Col>
                          <Col
                            lg="5"
                            className="d-flex justify-content-between overv-inside"
                          >
                            <h6>Owner</h6>
                            <h6 className="font-weight-bold">
                              {carInformation.owner}
                            </h6>
                          </Col>
                          <Col
                            lg="5"
                            className="d-flex justify-content-between overv-inside"
                          >
                            <h6>Variant</h6>
                            <h6 className="font-weight-bold">
                              {carInformation.varient}
                            </h6>
                          </Col>
                          <Col
                            lg="5"
                            className="d-flex justify-content-between overv-inside"
                          >
                            <h6>Insurance</h6>
                            <h6 className="font-weight-bold">
                              {carInformation.insurence == true ? "Yes" : "No"}
                            </h6>
                          </Col>
                          <Col
                            lg="5"
                            className="d-flex justify-content-between overv-inside"
                          >
                            <h6>RC</h6>
                            <h6 className="font-weight-bold">
                              {carInformation.rc == true ? "Yes" : "No"}
                            </h6>
                          </Col>
                          <Col
                            lg="5"
                            className="d-flex justify-content-between overv-inside"
                          >
                            <h6>Color</h6>
                            <h6 className="font-weight-bold text-capitalize">
                              {carInformation.color}
                            </h6>
                          </Col>
                        </Col>
                      </Col>
                    </Tab>
                    <Tab
                      eventKey="specification"
                      title="Specification"
                      className="flex specification"
                    >
                      <Col lg="12" className="d-flex">
                        {carDataDetail.specification &&
                          carDataDetail.specification.map((item, index) => (
                            <Col
                              key={index}
                              lg="12"
                              className="d-flex flex-wrap justify-content-between ps-2 pe-2 speci-wrap"
                            >
                              {item && Object.keys(item).map((key) => (
                                <Col
                                  key={key}
                                  lg="5"
                                  className="d-flex justify-content-between speci-inside"
                                >
                                  <h6>{key}</h6>
                                  <h6 className="font-weight-bold text-capitalize" style={{width:"200px",textAlign:"right"}}>
                                    {item[key]}
                                  </h6>
                                </Col>
                              ))}
                            </Col>
                          ))}
                      </Col>
                    </Tab>
                  </Tabs>
                </Col>
              </Col>
              <Col lg="5" className="carDetails-price-section">
                {userInfo._id == carInformation.userId && carInformation.city != 'pune'  ? (
                  <Col
                    lg="12"
                    className={
                      userInfo._id == carInformation.userId && carInformation.city != 'pune'
                        ? "car_leads"
                        : "car_leads d-none"
                    }
                  >
                    <div className="d-flex justify-content-between ps-2 pt-2 pe-2">
                      <h5 className="lead-heading m-0">Leads</h5>
                      <FaShareAlt onClick={handleShowModal} style={{fontSize:"22px",color:"gray"}}/>
                      <ShareModal show={showModal} onHide={handleHideModal} />
                    </div>
                    <ul className="car-leads-list">
                      {carsleads.length > 0
                        ? carsleads.map((leads, index) => (
                            <li key={index} className="lead-list-item mb-2">
                              <div className="lead-list-section d-flex">
                                <Col
                                  lg="2"
                                  className="d-flex justify-content-center align-items-center"
                                >
                                  <svg
                                    viewBox="0 0 256 256"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ width: "50px" }}
                                  >
                                    <rect fill="none" height="10" width="10" />
                                    <circle
                                      cx="128"
                                      cy="128"
                                      fill="none"
                                      r="96"
                                      stroke="#000"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="8"
                                    />
                                    <circle
                                      cx="128"
                                      cy="120"
                                      fill="none"
                                      r="40"
                                      stroke="#000"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="8"
                                    />
                                    <path
                                      d="M63.8,199.4a72,72,0,0,1,128.4,0"
                                      fill="none"
                                      stroke="#000"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="8"
                                    />
                                  </svg>
                                </Col>
                                <Col
                                  lg="5"
                                  className="d-flex justify-content-center align-items-center"
                                >
                                  <div className="name-No">
                                    <h6 className="w-100">{leads.userId}</h6>
                                    <h6 className="w-100 m-0">
                                      {leads.uniqueId.split("_")[0]}
                                    </h6>
                                  </div>
                                </Col>
                                <Col
                                  lg="5"
                                  className="d-flex justify-content-center align-items-center"
                                >
                                  <div className="date-price">
                                    <h6 key={index} className="w-100">
                                      {formatDate(leads.progress[0].updatedAt)}
                                    </h6>
                                    <h6 className="w-100 m-0">
                                      {
                                        Intl.NumberFormat("en-IN", {
                                          style: "currency",
                                          currency: "INR",
                                        })
                                          .format(leads.offerPrice)
                                          .split(".")[0]
                                      }
                                    </h6>
                                  </div>
                                </Col>
                              </div>
                            </li>
                          ))
                        : "leads not found"}
                    </ul>
                    {localStorage.hasOwnProperty("user") ? (
                      <div
                        className={
                          apiResponse.subscription == false
                            ? "subscription-btn"
                            : "subscription-btn d-none"
                        }
                      >
                        <Button
                          className="buttn-subscription"
                          onClick={handleAllPLansView}
                        >
                          Buy Subscription
                        </Button>
                        <ViewAllPlans
                          showAllPlan={showAllPlans}
                          closeAllPlan={handleAllPLansClose}
                          carPostId={carpostIdUrl}
                          city={carInformation.city}
                        />
                      </div>
                    ) : (<>
                      <div className="subscription-btn">
                        <Button className="buttn-subscription" onClick={handleModalShow}>Login</Button>
                      </div>
                      <LoginModal
                  show={showLoginModal}
                  handleClose={handleModalClose}
                />
                      </>
                    )}
                  </Col>
                ) : (
                  <Col
                    lg="12"
                    className={"car_detail"}
                  >
                    <div className="info-section d-flex col-12 justify-content-between">
                      <div className="col-10">
                        <h5 className="font-weight-bold carbrand-name">
                          {postedCarDetails.brandName}{" "}
                          <span>{carInformation.year}</span>
                        </h5>
                        <h6 className="model-name mb-3 d-flex">
                          {carInformation.model}{" "}
                          <span className="carsDot"> {carInformation.varient} </span> {" "}
                          <span className="carsDot text-uppercase">
                            {carInformation.carNumber
                              ? carInformation.carNumber
                                  .replace(/\s/g, "")
                                  .substring(0, 4)+ "-XX-XXXX"
                              : ""}
                          </span>
                        </h6>
                        <h6 className="fuel-head">
                          <span className="kms-fuel-transmission ms-0">
                            {(carInformation.kms / 1000).toLocaleString(
                              "en-US",
                              {
                                maximumFractionDigits: 1,
                              }
                            ) + "k"}{" "}
                            km
                          </span>
                          <span className="kms-fuel-transmission">
                            {carDataDetail.fuelType}{" "}
                          </span>
                          <span className="kms-fuel-transmission text-capitalize">
                            {carInformation.transmission}
                          </span>
                        </h6>
                      </div>
                      <div className="like-share">
                        <WishlistButton
                          wishlist={apiResponse.wishlisted}
                          uPostId={carInformation._id}
                          userId={
                            localStorage.hasOwnProperty("user")
                              ? userInfo._id
                              : ""
                          }
                        />
                      </div>

                        <FaShareAlt onClick={handleShowModal} style={{fontSize:"22px",color:"gray"}}/>
                        <ShareModal show={showModal} onHide={handleHideModal} />
                    </div>
                    <div className="drive-hub pt-3">
                      <h6 className="test-drive">
                        <span className="pe-1">
                          <svg
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.375 7.12492L9.5 1.58325L16.625 7.12492V15.8333C16.625 16.2532 16.4582 16.6559 16.1613 16.9528C15.8643 17.2498 15.4616 17.4166 15.0417 17.4166H3.95833C3.53841 17.4166 3.13568 17.2498 2.83875 16.9528C2.54181 16.6559 2.375 16.2532 2.375 15.8333V7.12492Z"
                              stroke="black"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M7.125 17.4167V9.5H11.875V17.4167"
                              stroke="black"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                        Home Test Drive : {carInformation.city != 'pune'  ? ("Unavailable"):("Available")}
                      </h6>
                      <h6 className="hub">
                        <span className="pe-1">
                          <svg
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_323_1442)">
                              <path
                                d="M16.625 7.9165C16.625 13.4582 9.50003 18.2082 9.50003 18.2082C9.50003 18.2082 2.37503 13.4582 2.37503 7.9165C2.37503 6.02684 3.1257 4.21456 4.46189 2.87837C5.79809 1.54217 7.61036 0.791504 9.50003 0.791504C11.3897 0.791504 13.202 1.54217 14.5382 2.87837C15.8744 4.21456 16.625 6.02684 16.625 7.9165Z"
                                stroke="#333333"
                                stroke-width="1.61006"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M9.50003 10.2915C10.8117 10.2915 11.875 9.22818 11.875 7.9165C11.875 6.60483 10.8117 5.5415 9.50003 5.5415C8.18835 5.5415 7.12503 6.60483 7.12503 7.9165C7.12503 9.22818 8.18835 10.2915 9.50003 10.2915Z"
                                stroke="#333333"
                                stroke-width="1.61006"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_323_1442">
                                <rect width="19" height="19" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                        Cars 2 Cars Hub, pune
                      </h6>
                    </div>
                    <div className="download-offer col-12">
                      <Button
                        className={
                          carInformation.city == "pune"
                            ? "download-btn"
                            : "download-btn d-none"
                        }
                        onClick={() => downloadReport(carBrandName.brandName, carInformation.model, carInformation.varient)}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <Spinner
                            animation="border"
                            variant="light"
                            size="sm"
                          />
                        ) : (
                          "Download Report"
                        )}
                      </Button>
                      {userInfo._id != carInformation.userId ? (
                        <>
                        <form>
                          <div className="col-12 make-offer d-flex">
                            <input
                              type="number"
                              className="form-control offer-input"
                              placeholder="Enter your offer Price"
                              onChange={handleOfferPrice}
                              value={
                                apiResponse.userOfferedPrice > 0
                                  ? apiResponse.userOfferedPrice
                                  : offerPrice
                              }
                            />
                            {localStorage.hasOwnProperty("user") ? (
                              <Button
                                className="offer-submit"
                                onClick={handleMakeOffer}
                                disabled={
                                  apiResponse.userOfferedPrice > 0
                                    ? true
                                    : parseInt(offerPrice) > minofferamount
                                    ? false
                                    : true
                                }
                                style={
                                  apiResponse.userOfferedPrice > 0
                                    ? {
                                        cursor: "not-allowed",
                                        pointerEvents: "all",
                                      }
                                    : parseInt(offerPrice) > minofferamount
                                    ? { cursor: "pointer" }
                                    : {
                                        cursor: "not-allowed",
                                        pointerEvents: "all",
                                      }
                                }
                              >
                                Submit
                              </Button>
                            ) : (
                              <Button
                                className="offer-submit"
                                onClick={showToast}
                              >
                                Submit
                              </Button>
                            )}
                          </div>
                        </form>
                        <h6
                          className="mt-1"
                          style={{ fontSize: "12px", fontWeight: "400" }}
                        >
                          Offer price must be greater than ₹ {minofferamount}
                        </h6>
                        </>
                      ) : ""}
                    </div>
                  </Col>
                )}
{userInfo._id === carInformation.userId ? (
  // Show the section for the car owner
  <div className="owner-specific-section">
   <img src={priceguidOwner}/>
   <Button className="p-absolute explore"  onClick={handleAllPLansView}>Explore Plan Details</Button>

   <ViewAllPlans
                          showAllPlan={showAllPlans}
                          closeAllPlan={handleAllPLansClose}
                          carPostId={carpostIdUrl}
                          city={carInformation.city}
                        />
  </div>
) : ( 
  // Show the price guide section for others
    <Col lg="12 mt-3 price-guid-section">
                  <div className="col-12 priceGuid">
                    <h5 className="guide-head">Price Guide</h5>
                    <p className="giud-text">
                      Cars Right Price guides you with the average listing price
                      for the current vehicle which is derived from thousands of
                      similar vehicle listings in your area on our platform.
                    </p>
                  </div>
                  <div className="bar-chart col-12">
                    {/* <canvas ref={chartRef} width={400} height={200}></canvas> */}
                    <Bar
          data={{
            // Name of the variables on x-axies for each bar
            labels: [
              `Great (${apiResponse.greatPrice})`,
          
              `Fair (${fairPrice})`,
             
              `High (${apiResponse.highPrice})`,
            ],
            datasets: [
              {             
                label: "Car Price",
                data: [
                  apiResponse.greatPrice,
                  fairPrice,
                  apiResponse.highPrice,
                ],
                backgroundColor: ["#D9D9D9", "rgba(247, 13, 2, 0.5)", "#D9D9D9"],
                // borderWidth: 0.5,
              },
            ],
          }}
          
          options={{
            maintainAspectRatio: false,
            scales: {
              yAxes: [
                {
                  ticks: { 
                    beginAtZero: true,                 
                  },
                },
              ],
              xAxes: [
                {
                  gridLines: {
                    display: false,
                    lineWidth: 0 // Remove x-axis gridlines
                  },
                },
              ],
       
            },
            legend: {
              labels: {
                fontSize: 15,
              },
            },
          }}
        />
                  </div>
                  <div className="price-section col-12 d-flex justify-content-between pt-2">

                    <div className="col-lg-12 text-center carprice">
                      <h5>Last Recorded Price</h5>
                      <h5 className="font-weight-bold">
                        {" "}
                        ₹ {apiResponse.lastRecordedPrice}
                      </h5>
                    </div>
                  </div>
                </Col> 
)}

                {/* <Col lg="12 mt-3 price-guid-section">
                  <div className="col-12 priceGuid">
                    <h5 className="guide-head">Price Guid</h5>
                    <p className="giud-text">
                      Cars Right Price guides you with the average listing price
                      for the current vehicle which is derived from thousands of
                      similar vehicle listings in your area on our platform.
                    </p>
                  </div>
                  <div className="bar-chart col-12">
                    <canvas ref={chartRef} width={400} height={200}></canvas>
                  </div>
                  <div className="price-section col-12 d-flex justify-content-between pt-2">

                    <div className="col-lg-12 text-center carprice">
                      <h5>Last Recorded Price</h5>
                      <h5 className="font-weight-bold">
                        {" "}
                        ₹ {apiResponse.lastRecordedPrice}
                      </h5>
                    </div>
                  </div>
                </Col> */}
              </Col>
            </Col>
            <Col lg="12" className="features-section pt-4 pb-3">
              <h5 className="feature-head text-center">
                Top Features of this Car
              </h5>
              <div className="col-12 d-flex feature-list">
                    <Col
                      lg="12"
                      className="d-flex flex-wrap d-flex feature-list"
                    >
                      {carDataDetail.feature &&
                        carDataDetail.feature.map((item, index) => (
                          <Col
                            key={index}
                            lg="12"
                            className="d-flex flex-wrap d-flex feature-list"
                          >
                            {item && Object.keys(item).map((key) => (
                              <Col
                                key={key}
                                lg="3"
                                className="d-flex justify-content-between border-right"
                              >
                                <div className="col-12 featuresList d-flex justify-content-between p-1">
                                  <div className="features">
                                    <h6>{key}</h6>
                                  </div>
                                  <div className="icon-check">
                                    <svg
                                      width="24"
                                      height="25"
                                      viewBox="0 0 24 25"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M20 6.98535L9 17.9854L4 12.9854"
                                        stroke="#F70D02"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              </Col>
                            ))}
                          </Col>
                        ))}
                    </Col>
              </div>
            </Col>
            <Col lg="12" className="similar-Cars pb-4">
              <h5 className="similar-heading text-center">SIMILAR CARS</h5>
              <Slider {...QuickSell}>
                {similarcars.length > 0 ? (
                  similarcars.map((similar, index) => (
                    <div key={index}>
                      <div className="carousel-slide quick-sell-slide">
                        <div className="col-12 car-details quick-car">
                          <img src={similar.carPost.carImages[0]} alt="" />
                          <Col lg="12" className="details">
                            <Col
                              lg="12"
                              className="d-flex justify-content-between"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate(
                                  "/SingleCarDetail?carPostId=" +
                                    similar.carPost._id
                                )
                              }
                            >
                              <Col lg="10">
                                <h5 className="mb-1">
                                  {similar.carPost.brandId}{" "}
                                  {similar.carPost.year}
                                </h5>
                                <h6 className="mb-2">
                                  {similar.carPost.model}{" "}
                                  {similar.carPost.varient} -{" "}
                                  <span className="text-uppercase">{similar.carPost.carNumber
                                    ? similar.carPost.carNumber
                                        .replace(/\s/g, "")
                                        .substring(0, 4)+ "-XX-XXXX"
                                    : ""}</span>
                                </h6>
                              </Col>
                              <Col lg="2">
                                <WishlistButton
                                  wishlist={similar.wishlisted}
                                  uPostId={similar.carPost._id}
                                  userId={
                                    localStorage.hasOwnProperty("user")
                                      ? userInfo._id
                                      : ""
                                  }
                                />
                              </Col>
                            </Col>
                            <Col lg="12">
                              <h6>
                                {(similar.carPost.kms / 1000).toLocaleString(
                                  "en-US",
                                  { maximumFractionDigits: 1 }
                                ) + "k"}{" "}
                                km{" "}
                                <span>
                                  <svg
                                    width="5"
                                    height="5"
                                    viewBox="0 0 5 5"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="2.5"
                                      fill="black"
                                    />
                                  </svg>
                                </span>{" "}
                                <span className="text-capitalize">
                                  {similar.fuelType}
                                </span>{" "}
                                <span>
                                  <svg
                                    width="5"
                                    height="5"
                                    viewBox="0 0 5 5"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="2.5"
                                      fill="black"
                                    />
                                  </svg>
                                </span>{" "}
                                {similar.carPost.transmission}
                              </h6>
                            </Col>
                          </Col>
                          <Col lg="12" className="p-2">
                            <svg
                              width="21"
                              height="21"
                              viewBox="0 0 21 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_434_2834)">
                                <path
                                  d="M18.3814 8.46854C18.3814 14.3784 10.783 19.444 10.783 19.444C10.783 19.444 3.18457 14.3784 3.18457 8.46854C3.18457 6.45331 3.98512 4.52062 5.4101 3.09564C6.83508 1.67066 8.76777 0.870117 10.783 0.870117C12.7982 0.870117 14.7309 1.67066 16.1559 3.09564C17.5809 4.52062 18.3814 6.45331 18.3814 8.46854Z"
                                  stroke="#333333"
                                  stroke-width="1.71704"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M10.7828 11.0014C12.1816 11.0014 13.3156 9.86743 13.3156 8.4686C13.3156 7.06977 12.1816 5.93579 10.7828 5.93579C9.38398 5.93579 8.25 7.06977 8.25 8.4686C8.25 9.86743 9.38398 11.0014 10.7828 11.0014Z"
                                  stroke="#333333"
                                  stroke-width="1.71704"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_434_2834">
                                  <rect
                                    width="20.2625"
                                    height="20.2625"
                                    fill="white"
                                    transform="translate(0.651611 0.026001)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                            <span className="m-1">Cars 2 Cars Hub, pune</span>
                          </Col>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <h5 className="text-color text-center m-0 mt-2">
                    Cars Not Found
                  </h5>
                )}
              </Slider>
            </Col>
          </Col>
        </Container>
      )}
      <ToastContainer />
    </>
  );
};
export default SingleCarDetail;
